html {
  overflow-y: scroll;
}

body {
  margin: 0;
}

main {
  margin-top: 100px;
  margin-bottom: 50px;
  min-height: calc(100vh - 150px - 56px);
}

nav .MuiTypography-root,
.sr-only {
  position: absolute;
  width: 0px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
}

@media screen and (min-width: 900px) {
  nav .MuiTypography-root {
    position: unset;
    width: unset;
    height: unset;
    margin: unset;
    padding-left: .5rem;
    overflow: unset;
  }
}